/**
 * listener on an input to calculate total price
 *
 *
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */


export default class PedestrianStep2Controller {

  constructor(element) {
    this.element = element; // the form

    this.total_fee_element = document.querySelector('[data-role="total-fee"]');
    this.numberInputs = document.querySelectorAll('input[type="number"]');

    //loop over all number inputs
    [...this.numberInputs].forEach((el) => {

      el.addEventListener('input', (event) => {
          event.preventDefault();
          this.calculatePrice();

      });

    });

    this.calculatePrice(); // onload calculate as well

  }

  calculatePrice() {

      let total_fee = parseFloat(this.total_fee_element.getAttribute('data-total-initial'));

      // calculate totalprice, by looping over everything
      [...this.numberInputs].forEach((el) => {
          let inputname = el.name;
          let inputcount = el.value;

          productPriceMap.forEach((listitem)=> {
            if (listitem['id'] === inputname) {
              let price = listitem['price'];
              let add_amount = price * inputcount;
              total_fee += add_amount;
            }
          })

      });

      this.total_fee_element.innerHTML = parseFloat(total_fee).toFixed(2).replace('.', ','); + ' euro';

  }

}
