import loadScript from '../utils/load-script'

export default class RecaptchaField {
  static name = 'recaptcha-field';
  constructor (el) {
    this.el = el;
    this.key = el.getAttribute('data-key');
    el.removeAttribute('data-key');

    this.initialize();
  }

  initialize () {
    if (this.key) {
      loadScript('recaptcha', `https://www.google.com/recaptcha/api.js?render=${this.key}`, this.onRecaptchaLoaded.bind(this));
    } else {
      console.warn('Recaptcha key missing!');
    }
  }

  onRecaptchaLoaded () {
    grecaptcha.ready(() => {
      this.refreshToken();
    });
  }

  refreshToken () {
    window.grecaptcha.execute(this.key).then((token) => {
      this.el.setAttribute('value', token);
      setTimeout(this.refreshToken.bind(this), 60 * 1000);
    })
  }
}
