/**
 * listener on minus and plus buttons for tickets
 *
 *
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */


export default class PlusMinusController {

  constructor(element) {
    this.element = element; // the container

    this.element = element;

    this.input = this.element.querySelector('input[type="number"]');
    this.minusButtons = this.element.querySelector('a.spinner-minus');
    this.plusButtons = this.element.querySelector('a.spinner-plus');

    this.minusButtons.addEventListener('mousedown', this.countDecrement.bind(this), false);
    this.minusButtons.addEventListener('touchstart', this.countDecrement.bind(this), false);
    //
    this.plusButtons.addEventListener('mousedown', this.countIncrement.bind(this), false);
    this.plusButtons.addEventListener('touchstart', this.countIncrement.bind(this), false);

    // this.total_fee_element = document.querySelector('[data-role="total-fee"]');
    //
    // //loop over all number inputs
    // [...this.day_selector].forEach((el) => {
    //
    //   el.addEventListener('click', (event) => {
    //     this.updateTotalPrice(false, event.target.value);
    //   });
    //
    // });

    this.input.addEventListener('keyup', (event) => {
      this.validateValue(event.target);
    })

  }

  validateValue(input) {
    var minValue, maxValue;

    if (!input) {
      return false;
    }

    input.value = parseInt(input.value ? input.value.replace(/[?\-]\D/g, '') : 0, 10);

    minValue = parseInt(input.getAttribute('min') || 0, 10);
    maxValue = parseInt(input.getAttribute('max') || 999999, 10);

    if (!input.value) {
      input.value = minValue;

      return true;
    }

    if (+input.value < minValue) {
      input.value = minValue;

      return false;
    }

    if (+input.value > maxValue) {
      input.value = maxValue;

      return false;
    }

    return true;
  }

  countIncrement(event) {

    var target = event.target || event.srcElement,
        input = target.parentNode.querySelector('input[type="number"]');

    input.value = parseInt(1 + (+input.value), 10);
    this.validateValue(input);
    this.triggerInput(input);  // this calculated the amount based on specific flow logic

    if (event) {
      event.preventDefault();
    }

  }

  countDecrement(event) {

      var target = event.target || event.srcElement,
          input = target.parentNode.querySelector('input[type="number"]');

      if (!input) {
        return;
      }

      input.value = parseInt((+input.value) - 1, 10);
      this.validateValue(input);
      this.triggerInput(input);

      if (event) {
        event.preventDefault();
      }

  }

  triggerInput(inputelement) {
    var event = document.createEvent('Event');
    event.initEvent('input', true, true);
    inputelement.dispatchEvent(event);
  }


}
