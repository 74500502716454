/**
 * listener on a license plate input and vehicle length
 *
 * sorry for jquery, but ajax requests with vanillaJS is meh
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */

import $ from 'jquery';

export default class LongVehicleStep2Controller {

  constructor(element) {
    this.element = element; // the form

    const licenseInput = document.querySelector('input[name="license_plate"]');
    const lengthSelector = document.querySelector('select[name="vehicle_length"]');
    const licenseCheckbox = document.querySelector('label[for="id_unknown_license_plate"]')
    this.day_selector = document.querySelectorAll('input[name="departure_day"]');

    this.vehicle_length = lengthSelector[lengthSelector.selectedIndex].value;//'';
    // this.departure_day  = '';

    licenseCheckbox.addEventListener('click', (event) => {
      this.getLicensePlateState('123456');
    })

    licenseInput.addEventListener('input', (event) => {
      event.preventDefault();

      event.target.value = event.target.value.replace(/[^\p{Letter}\p{Mark}\p{Number}\-]/giu, '');

      if (event.target.value.length < 5) {
        return false;
      }

      let ivalue = event.target.value;
      ivalue = ivalue.replace(/-/g,"").replace(/ /g,"");

      this.getLicensePlateState(ivalue);
    });

    this.total_fee_element = document.querySelector('[data-role="total-fee"]');

    lengthSelector.addEventListener('change', (event) => {
      this.vehicle_length = event.target.value;

      let ivalue = licenseInput.value.replace(/[^\p{Letter}\p{Mark}\p{Number}\-]/giu, '');
      ivalue = ivalue.replace(/-/g,"").replace(/ /g,"");

      this.getLicensePlateState(ivalue);
      this.updateTotalPrice();
    });

    //loop over all number inputs
    [...this.day_selector].forEach((el) => {
      el.addEventListener('click', (event) => {
        this.departure_day = event.target.value;
        this.updateTotalPrice();
      });

    });
  }

  updateTotalPrice() {

      let total_fee = parseFloat(this.total_fee_element.getAttribute('data-total-initial'));

      productPriceMap.forEach((listitem)=> {

        if (this.texel_plate === true) {

          if (listitem['vehicle_length'] === this.vehicle_length && listitem['texel_plate'] === true) {
            let price = listitem['price'];
            total_fee = price;
          }

        } else {

          if (!this.departure_day) {
            return;
          }

          if (listitem['vehicle_length'] === this.vehicle_length && listitem['texel_plate'] === false && listitem['value'] === this.departure_day) {
            let price = listitem['price'];
            total_fee = price;
          }

        }

      });

      if (total_fee > 0) {
        this.total_fee_element.innerHTML = parseFloat(total_fee).toFixed(2).replace('.', ',') +' euro';
      }
  }

  resetPrice() {
    this.total_fee_element.innerHTML = '-';
  }

  getLicensePlateState(platenr) {

    const departure_days_container = document.querySelector('[data-role="departure_days"]');
    let self = this;

    $.ajax({
        type: "GET",
        url: "/api/check_license_plate_state/"+platenr+"/",
        success: function(response) {

          if (response.status === true) {  // texel plate TRUE, no days selector, same fee applies
            self.texel_plate = true;
            departure_days_container.classList.remove('active');
            //deselect all options
            [...self.day_selector].forEach((el) => {
              el.checked = false;
            });
            self.updateTotalPrice();
          } else {
            self.texel_plate = false;
            departure_days_container.classList.add('active');
            //deselect all options, for price calculation and forcing a choice
            [...self.day_selector].forEach((el) => {
              el.checked = false;
            });
            self.resetPrice();
          }

        },
        error: function(jqXHR, textStatus, errorThrown) {
          console.log('ERROR')
        },
    });

  }



}
