export default function loadScript(id, src, callback) {
  if (!document.getElementById(id)) {
    const _tag = document.createElement('script');

    _tag.src = src;
    _tag.async = _tag.defer = true;
    _tag.onload = callback;
    _tag.id = id;
    _tag.type = 'text/javascript';

    const _firstScriptTag = document.getElementsByTagName('script')[0];
    _firstScriptTag.parentNode.insertBefore(_tag, _firstScriptTag);
  } else {
    callback();
  }
}
